@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }

  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }

  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}

.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }

  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 40px;

  @media(max-width: 768px) {
    padding: 40px 23px;
  }

  @media(min-width: 1500px) {
    max-width: 1440px;
    margin: 0 auto;
  }

  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 24px 0 0 0;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 0 0 0;
  }
}

.searchFiltersMobileMap {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  /* padding: 0 24px; */

  @media (--viewportLarge) {
    /* padding: 0 36px; */
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

.firstInput {
  position: relative;
  display: none;

  & input {
    padding-left: 41px;
  }

  & .searchIcon {
    position: absolute;
    left: 9px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.searchFilerBox {
  /* display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center; */
  margin-bottom: 20px;

  @media(max-width: 768px) {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-end;

  }
}

.schoolGrid {
  display: flex;
  margin-top: 42px;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row-reverse;
  align-items: flex-end;

  @media(max-width: 920px) {
    flex-wrap: wrap;
    margin-top: 0;
    flex-direction: unset;
    align-items: flex-start;
  }
}

.editHeading {
  display: block;

  @media(max-width: 600px) {
    display: none;
  }

  & h2 {
    color: #717171;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;

    & span {
      margin-left: 17px;
      cursor: pointer;
    }
  }
}

.desktopTitle {
  display: none;
  margin-bottom: 39px;

  @media(max-width: 768px) {
    margin-bottom: 18px;
  }

  & h2 {
    color: #717171;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: 0;

    & span {
      margin-left: 17px;
      cursor: pointer;
    }
  }
}

.schoolButton {
  display: flex;
  gap: 30px;

  @media(max-width: 768px) {
    /* gap: 10px; */
  }

  & button {
    border-radius: 0;
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    width: fit-content;
    padding: 0 8px !important;
    border-bottom: 2px solid transparent;
    border: none;
    color: #B0B0B0;
    background: transparent;

    @media(max-width: 768px) {
      height: 32px;
      min-height: 32px;
      padding: 0;
      font-size: 16px;
      /* width: 200px; */
      line-height: normal;
    }

    @media(max-width: 500px) {
      /* width: 125px; */
    }
  }
}

.sportFormBox {
  & form {
    &>div {
      flex-direction: row;

      &>div {
        width: 200px;
        margin: unset;

        @media(max-width: 460px) {
          width: 100%;
        }
      }

      & button {
        width: 150px;

        @media(max-width: 460px) {
          width: 100%;
        }
      }
    }
  }
}

.learningActive {
  border-bottom: 2px solid #105446 !important;
  color: #105446 !important;
  border: none;
}

.resetButton {
  display: none;
  margin-bottom: 20px;
  width: 100%;
  color: #105446;
  text-decoration: underline;
  text-align: right;
}